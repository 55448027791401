import gql from 'graphql-tag'
export const CANDIDATE_VIEWS = gql `
  query getCandidateViews($candidateId: ID) {
    views: getCandidateViews(candidateId: $candidateId) {
      views
      candidate{
        fullName
      }
    }
  }
`
