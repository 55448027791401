import gql from 'graphql-tag'

export const UPDATED_VIEWS = gql `
subscription candidateCandidacyView{
  candidateCandidacyView{
    views
    candidate{
      id
      fullName
    }
  }
}
`
export const NEW_CERTIFICATE = gql `
subscription candidateCertificate{
  candidateCertificate{
    id
    certificates
  }
}
`