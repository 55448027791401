<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="350"
  >
    <v-card class="pb-2">
     <v-card-title class="justify-end primary--text caption pa-1">
        <v-btn x-small icon @click="$emit('no', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="primary--text text-center justify-center pt-1">
        <span class="pt-1 black--text" v-html="question">{{ question }}</span>
        <br /><br />
        <v-row justify="center">
          <v-col>
            <v-btn block color="primary" outlined @click="$emit('yes', id)">{{
              $t("yes")
            }}</v-btn>
          </v-col>
          <v-col>
            <v-btn block color="primary" @click="$emit('no', false)">{{
              $t("no")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    dialog: Boolean,
    question: String,
    id: String,
  },
}
</script>