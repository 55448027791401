import gql from 'graphql-tag'
import candidateFragment from './CandidateFragment'

export const REMOVE_CERTIFICATE_MUTATION = gql`
  mutation RemoveCertificate($index: Int!) {
    removeCertificate(index: $index)
  }
`

export const UPDATE_CANDIDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateCandidateAccount($candidateInput: CandidateInput) {
    updateCandidateAccount(candidateInput: $candidateInput) {
      ...${candidateFragment}
    }
  }
`
 
export const UPLOAD_CENTIFICATE_MUTATION = gql`
  mutation UploadCertificate($certificate: Upload!) {
    uploadCertificate(certificate: $certificate)
  }
`