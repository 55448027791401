<template>
  <div>
    <div class="mt-5 pr-5">
      <div class="body mb-5 text-uppercase black--text">
        {{ $t("vacancies_applyed_for") }}
      </div>
    </div>
    <!-- <pre>{{ filteredItems }}</pre> -->
    <v-card v-if="$apollo.loading">
      <v-skeleton-loader
        :type="`table-heading, list-item-two-line@${filteredItems.length}`"
      ></v-skeleton-loader>
    </v-card>
    <v-simple-table dense fixed-header style="width: 100%;" v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t("vacancies") }}
            </th>
            <th class="text-left">
              {{ $t("doc_label") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filteredItems" :key="item.id">
            <td>{{ item.title }}</td>
            <td>
              <!-- <pre>{{ getCandidancy(item).documents }}</pre> -->
              <v-chip
                class="ma-2"
                color="primary"
                text-color="white"
                v-for="doc in getCandidancy(item).documents"
                :key="doc"
                small
              >
                <v-avatar left>
                  <v-icon small>mdi-file</v-icon>
                </v-avatar>
                <a
                  :href="`${apiUrl}/files/vacancy/documents/${doc}`"
                  target="_blank"
                  style="color: #fff;"
                >
                  {{ doc }}</a
                >
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { API_URL } from "@/api";
import { GET_VACANCIES_CANDIDATE_QUERY } from "./../../../dashboard/graphql/Query";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "VacanciesDetails",
  components: {},
  mixins: [],
  props: {
    vacancies: Array,
    candidate_id: String,
  },
  data: () => ({
    apiUrl: API_URL,
    filter: {
      areas: undefined,
      place: undefined,
    },
    Vacancies: [],
    textSearch: undefined,
    appliedFilter: true,
  }),
  apollo: {
    Vacancies: {
      query: GET_VACANCIES_CANDIDATE_QUERY,
      variables() {
        return { filterVacancy: this.filter };
      },
      // fetchPolicy: "no-cache",
    },
  },
  watch: {
    Vacancies: function(val) {
      console.log("valores", val);
      if (this.appliedFilter) {
        this.setVacanciesState(val);
        this.appliedFilter = false;
      } else {
        this.setVacancyScrolled(val);
      }
    },
  },
  computed: {
    ...mapGetters({
      getVacancies: "vacancy/getVacancies",
    }),
    filteredItems() {
      let resultItems = [...this.Vacancies];
      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.title).includes(text)
        );
      }
      resultItems = resultItems.filter(
        (v) =>
          v.entity != null &&
          v.candidacies.find((c) => c.documents && c.documents.length)
      );
      return resultItems;
    },
  },
  mounted() {},
  created() {},
  methods: {
    ...mapActions({
      setVacanciesState: "vacancy/setVacancies",
      setVacancyScrolled: "vacancy/setVacancyScrolled",
    }),
    getCandidancy(vacancy) {
      return vacancy.candidacies
        ? vacancy.candidacies.find(
            (c) => c.candidate && c.candidate.id === this.candidate_id
          )
        : null;
    },
  },
};
</script>
<style scoped>
.subtile {
  font-size: 1.2rem !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.small-perfil:hover {
  border: 1px solid #f7a400;
}
.show-btns:not(.on-hover) {
  color: #f7a400 !important;
}
.ajustaEspaco {
  margin-top: -3%;
  margin-bottom: -2.5%;
}
.ajustaEspaco2 {
  margin-top: -4%;
  margin-bottom: -0.5%;
}
@media screen and (min-width: 300px) and (max-width: 1366px) {
  .ajustaEspaco {
    margin-top: -4%;
    margin-bottom: -3.5%;
  }
  .ajustaEspaco2 {
    margin-top: -6%;
    margin-bottom: -1.7%;
  }
}
</style>
